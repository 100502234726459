import _ from "lodash";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { UserRole } from "@sumit-platforms/types";
import { Button } from "../../core/Button/Button";
import Input from "../../core/Input/Input";
import { MultiSelect } from "../../core/MultiSelect/MultiSelect";
import { emailRegex } from "../../utils/regex";

import "./InviteNewUserModal.scss";

export interface InviteNewUserModalProps {
  onInvite: (email: string, role: UserRole) => void;
  onCancel: () => void;
  toast: {
    success: (text: string) => void;
    error: (text: string) => void;
    info: (text: string) => void;
  };
}

export const InviteNewUserModal = ({
  onInvite,
  onCancel,
  toast,
}: InviteNewUserModalProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRole[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isDetailesValid = useMemo(() => {
    return !_.isEmpty(role) && emailRegex.test(email);
  }, [role, email]);

  const handleOnInvite = async () => {
    try {
      if (isDetailesValid) {
        setIsLoading(true);
        toast.info(t("invite_new_user"));
        await onInvite(email, role[0]);
        setIsLoading(false);
        toast.success(t("invitation_sent"));
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.error(err?.message ? t(err?.message) : "");
    }
  };

  const handleOnEmailChange = (e: any) => {
    setEmail(e.target?.value || "");
  };

  return (
    <Box className="InviteNewUserModal" py={2} px={4}>
      <Box
        className="title"
        display={"flex"}
        justifyContent={"center"}
        pb={"20px"}
      >
        {t("invite_new_user")}
      </Box>
      <Box
        display={"flex"}
        pb={2}
        justifyContent={"start"}
        className={"emailInputWrapper"}
      >
        <Input
          placeholder={t("email")}
          value={email}
          regex={emailRegex}
          type="text"
          onChange={handleOnEmailChange}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"start"}
        pb={2}
        className={"rolesInputWrapper"}
      >
        <MultiSelect
          selected={role}
          setSelected={(selected) => setRole(selected)}
          placeholder={t("select_user_role")}
          isMulti={false}
          options={[
            {
              label: t(UserRole.COMMUNITY_MEMBER.toLowerCase()),
              value: UserRole.COMMUNITY_MEMBER,
            },
          ]}
        />
      </Box>
      <Box display="flex" justifyContent={"center"} gap={1}>
        <Button disabled={isLoading} onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          loading={isLoading}
          disabled={!isDetailesValid}
          onClick={handleOnInvite}
          color="primary"
        >
          {t("invite")}
        </Button>
      </Box>
    </Box>
  );
};
