import axios from "axios";
import {
  CommunityPayment,
  CommunityPaymentResponse,
} from "@sumit-platforms/types";
import config from "../config";

const communityPaymentService = () => {
  const endpointUrl = `${config.server.host}/${config.server.communityPayments}`;

  const getUserPayments = async (): Promise<CommunityPaymentResponse> => {
    try {
      return (await axios.get<CommunityPaymentResponse>(`${endpointUrl}`)).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCommunityPaymentsByUser = async (
    idUser: number
  ): Promise<CommunityPaymentResponse> => {
    try {
      return (
        await axios.get<CommunityPaymentResponse>(`${endpointUrl}/${idUser}`)
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createNewPayment = async (payment: Partial<CommunityPayment>) => {
    try {
      return (await axios.post<CommunityPayment>(`${endpointUrl}`, payment))
        .data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removePayments = async (idPayment: number) => {
    try {
      return (
        await axios.delete<{ idPayment: number }>(`${endpointUrl}/${idPayment}`)
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getUserPayments,
    getCommunityPaymentsByUser,
    createNewPayment,
    removePayments,
  };
};
export default communityPaymentService();
