import config from "./config";
import { version } from "../project.json";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { I18nProvider } from "./i18n/I18nProvider";
import {
  getFeatureFlagContext,
  ShortcutsProvider,
} from "@sumit-platforms/ui-bazar/hooks";
import "./axios.config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Delivery } from "./Delivery";

import "./index.scss";
Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://opera.sumit-ai.com"],
    }),
    Sentry.replayIntegration(),
  ],
  environment: config.environment,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: version,
});

const queryClient = new QueryClient();

const FeatureFlagProvider = getFeatureFlagContext({ config });
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <I18nProvider>
    <FeatureFlagProvider>
      <ShortcutsProvider>
        <QueryClientProvider client={queryClient}>
          <Delivery />
        </QueryClientProvider>
      </ShortcutsProvider>
    </FeatureFlagProvider>
  </I18nProvider>
);
