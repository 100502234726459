import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Client,
  Job,
  JobCommunityMembers,
  JobWithPayment,
} from "@sumit-platforms/types";
import {
  AvailableHeadCellsKeys,
  useHeadCells,
} from "@sumit-platforms/ui-bazar/hooks";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  getJobCommunityMembers,
  formatPaymentTypeFromJobWithPayment,
  getPriceStringWithCurrency,
} from "@sumit-platforms/ui-bazar/utils";
import {
  ContextOption,
  GeneralTable,
  Option,
  SearchAndFilters,
} from "@sumit-platforms/ui-bazar";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";

const defaultQuery = {
  month: null,
  year: null,
};

const headCellsKeys: AvailableHeadCellsKeys[] = [
  "id",
  "name",
  "client",
  "paymentType",
  "members",
  "duration",
  "deliveryWithTranscribeDelivery",
  "payment",
  "actions",
  "contextMenu",
];

type MonthPaymentRow = {
  id: number;
  key: string;
  name: string;
  client: Client;
  paymentType: string;
  duration: number;
  delivery: Date;
  members: JobCommunityMembers;
  payment: string;
  projectName: string;
  disabled: boolean;
  paymentDate?: Date;
  job: Job;
  isBonus?: boolean;
};

interface CommunityMemberMonthPaymentsTableProps {
  month: string;
  year: string;
  jobs: JobWithPayment[];
  isJobsLoading: boolean;
  openCommunityBonusModal: () => void;
  shouldShowBonusModal: boolean;
  onRemoveBonus: (id: number) => void;
}
const CommunityMemberMonthPaymentsTable = ({
  jobs,
  isJobsLoading,
  openCommunityBonusModal,
  shouldShowBonusModal,
  onRemoveBonus,
}: CommunityMemberMonthPaymentsTableProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [rows, setRows] = useState<MonthPaymentRow[]>([]);
  const initialRows = useRef<MonthPaymentRow[]>([]);
  const { headCells } = useHeadCells({
    headCellsKeys,
    labelIcon: { contextMenu: shouldShowBonusModal ? faPlusCircle : null },
    labelAction: {
      contextMenu: openCommunityBonusModal,
    },
    labelTooltip: {
      contextMenu: t("add_community_bonus"),
    },
    styles: {
      name: { width: "22%" },
      members: { width: "14%" },
      paymentType: { width: "13%" },
    },
    selectedRows: rows.filter((row) => selected.includes(row.key)),
    tableContextCallBack: getTableContextMenu,
  });

  useEffect(() => {
    const rows = jobs?.length ? jobs.map((job) => createJobRow(job)) : [];
    setRows(rows);
    initialRows.current = rows;
  }, [jobs]);

  const onSearch = (e: any) => {
    const newRows = initialRows.current.filter((r) =>
      r.name.toLowerCase().includes(e.target.value)
    );
    setRows(newRows);
  };

  const createJobRow = (jobWithPayment: JobWithPayment) => {
    const members = getJobCommunityMembers(jobWithPayment);
    const paymentType = formatPaymentTypeFromJobWithPayment(jobWithPayment, t);
    return {
      key: jobWithPayment?.idCommunityPayment,
      paymentType,
      isBonus: jobWithPayment?.isBonus,
      id: jobWithPayment?.idJob,
      name: jobWithPayment?.name || jobWithPayment?.paymentDescription,
      duration: jobWithPayment.duration || 0,
      delivery:
        jobWithPayment?.delivery && new Date(jobWithPayment?.delivery || ""),
      client: jobWithPayment?.client,
      members,
      disabled: false,
      projectName: jobWithPayment?.project?.name,
      payment: getPriceStringWithCurrency({
        price: jobWithPayment?.payment as number,
        currency: "ILS",
      }),
      paymentDate: jobWithPayment?.paymentDate,
      job: jobWithPayment,
    } as MonthPaymentRow;
  };

  const handleRemoveCommunityBonus = useCallback(
    (row: MonthPaymentRow) => {
      onRemoveBonus(+row.key);
    },
    [onRemoveBonus]
  );

  function getTableContextMenu(row: MonthPaymentRow) {
    return [
      {
        name: t("remove"),
        action: () => handleRemoveCommunityBonus(row),
        disabled: !row.isBonus,
      },
    ] as ContextOption[];
  }

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid item xs={11} my={1}>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${rows.length} ${t("from")} ${
            initialRows.current.length
          } ${t("jobs").toLowerCase()}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          direction={t("dir") as any}
          onSearch={onSearch}
          isLoading={false}
          searchPlaceholder={`${t("search")} ${t("job").toLowerCase()}`}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
        />
      </Grid>
      <Grid xs={11} item>
        <GeneralTable
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
          allowSelect={true}
          loading={isJobsLoading}
          hasMore={false}
          selectBy={"key"}
        />
      </Grid>
    </Grid>
  );
};

export default CommunityMemberMonthPaymentsTable;
