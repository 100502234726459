export default {
  environment: "development",
  envUrl: "https://dev.opera.sumit-ai.com",
  server: {
    host: "https://dev.opera-server.sumit-ai.com",
    auth: "auth",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    files: "files",
    export: "export",
    clients: "clients",
    docxTemplates: "docxTemplates",
    assignments: "assignments",
    validationPresets: "validationPresets",
    communityPayments: "communityPayments",
    invoices: "invoices",
  },
  googleAuthProvider: {
    apiKey: "AIzaSyB9fmJ3aM8i3V_beh_nnX3-WxWs-M15IEg",
    clientId:
      "360745002639-ghl8v1tkaumose81fbo6jhejo5g7e3sm.apps.googleusercontent.com",
  },
  firebase: {
    clientId:
      "360745002639-podsug40kta99fop77a2mrtruoc83sge.apps.googleusercontent.com",
    apiKey: "AIzaSyB9fmJ3aM8i3V_beh_nnX3-WxWs-M15IEg",
    authDomain: "sumit-dev-0.firebaseapp.com",
    projectId: "sumit-dev-0",
    storageBucket: "sumit-dev-0.appspot.com",
    messagingSenderId: "360745002639",
    appId: "1:360745002639:web:51ada10804ecd6fe87f2fa",
    measurementId: "G-1EPFNZV0VQ",
    tenantId: "b2b-platform-jaug4",
  },
  featureFlags: {
    apiKey:
      "opera:development.40827488ed028516fea224fac5156e5c5c99a185cc4a3f98a04c0a91",
  },
  cloudinary: {
    cloud_name: "df9neab8j",
    upload_preset: "ejh79zy5",
    api_key: "724134266828553",
  },
};
