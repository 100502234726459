import React, { useCallback, useState } from "react";
import { Button, Modal } from "../../../index";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

interface DeleteCommunityPaymentModal {
  idCommunityPayment: number;
  onApprove: (idCommunityPayments: number) => void;
  closeModal: () => void;
}

export const DeleteCommunityPaymentModal = ({
  idCommunityPayment,
  onApprove,
  closeModal,
}: DeleteCommunityPaymentModal) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleOnApprove = useCallback(async () => {
    setLoading(true);
    try {
      await onApprove(idCommunityPayment);
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
      closeModal();
    }
  }, []);
  return (
    <Modal closeModal={closeModal} type={"info"}>
      <Box className={"DeleteCommunityPaymentModal"} p={3}>
        <Box textAlign={"center"}>
          <Typography variant="h5" color="text.primary">
            {t("delete_community_payment")}
          </Typography>
        </Box>
        <Box py={2} textAlign={"center"}>
          <Typography variant="body2" color="text.secondary">
            {t("delete_community_payment_description")}
          </Typography>
        </Box>
        <Box
          component={"div"}
          pt={1}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          <Button variant={"outlined"} onClick={closeModal}>
            {t("cancel")}
          </Button>
          <Button onClick={handleOnApprove} loading={loading} color={"warning"}>
            {t("delete")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
