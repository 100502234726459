import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  Breadcrumbs,
  CommunityBonusForm,
  DeleteCommunityPaymentModal,
  Option,
} from "@sumit-platforms/ui-bazar";
import {
  CommunityPayment,
  JobWithPayment,
  User,
  UserStatus,
} from "@sumit-platforms/types";
import useUsers from "../../hooks/useUsers";
import { useParams, useSearchParams } from "react-router-dom";
import CommunityTable from "./components/CommunityTable";
import CommunityMemberProfile from "./components/CommunityMemberProfile";
import { formatMonthObject, monthNames } from "@sumit-platforms/ui-bazar/utils";
import CommunityMemberMonthPaymentsTable from "./components/CommunityMemberMonthPaymentsTable";
import useJobs from "../../hooks/useJobs";
import { usePayments } from "../../hooks/usePayments";
import _ from "lodash";
import { endOfMonth, subMonths } from "date-fns";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { useGlobalData } from "../../store/globalData";
import { useToast } from "@sumit-platforms/ui-bazar/hooks";
import communityPaymentsService from "../../services/communityPaymentsService";

import "./Community.scss";

export interface UserRow {
  id: number;
  name: string;
  contact: any;
  status: UserStatus;
  paymentAndCapacity: any;
  context: any;
  user: User;
  mfaActive?: boolean;
  isLoading?: boolean;
}

const defaultQuery: any = {
  communityMemberStatus: [],
  year: null,
  month: null,
};

export const Community: FC = () => {
  const { t } = useTranslation();
  const { setModalType, setModalContent, clearModalContent } = useModal();
  const { setToast } = useGlobalData();
  const { toastError, toastSuccess } = useToast({ setToast });
  const [searchParams] = useSearchParams();
  const [month, setMonth] = useState<null | string>(searchParams.get("month"));
  const [year, setYear] = useState<null | string>(searchParams.get("year"));
  const { idUser } = useParams();
  const {
    users,
    getUsers,
    metaData,
    isLoading: isUsersLoading,
    hasMore,
    totalUsers,
  } = useUsers();

  const {
    jobs,
    getJobs,
    setJobs,
    isLoading: isJobsLoading,
  } = useJobs({ jobType: "communityMemberMonth" });

  const [clickedUser, setClickedUser] = useState<User | null>(null);
  const availableMonthToGrantBonus: Option[] = useMemo(() => {
    return getAvailableMonthsToGrantBonus();
  }, [month, year]);

  const {
    getUserCommunityPayments,
    setCommunityPayments,
    communityPayments,
    metaData: paymentsMetadata,
    isLoading: isPaymentsLoading,
  } = usePayments();

  useEffect(() => {
    const monthFromUrl = searchParams.get("month") || null;
    if (monthFromUrl !== month) setMonth(monthFromUrl);
    const yearFromUrl = searchParams.get("year") || null;
    if (yearFromUrl !== year) setYear(yearFromUrl);
  }, [searchParams]);

  useEffect(() => {
    if (idUser && +idUser !== clickedUser?.idUser) {
      if (!_.isEmpty(users)) {
        const clickedUser = users.find((user) => +user.idUser === +idUser);
        getUserCommunityPayments(clickedUser?.idUser);
        setClickedUser(clickedUser || null);
      } else {
        getUsers();
      }
    }
    if (!idUser) {
      setClickedUser(null);
      setCommunityPayments([]);
    }
  }, [idUser, users]);

  const getCrumbs = useCallback(() => {
    const crumbs = [{ label: t("community"), path: "/community" }];
    if (clickedUser) {
      crumbs.push({
        label: `${clickedUser.firstName} ${clickedUser.lastName}`,
        path: `/community/${clickedUser.idUser}?tab=payments`,
      });
      if (month && year) {
        crumbs.push({
          label: `${t(monthNames[month])} ${year.toString().slice(-2)}`,
        } as any);
      }
    }
    return crumbs;
  }, [clickedUser, month, year]);

  useEffect(() => {
    getChosenPaymentJobs();
  }, [year, month, communityPayments]);

  const getChosenPaymentJobs = () => {
    if (!communityPayments.length) {
      setJobs([]);
      return;
    }
    if (year && month) {
      const chosenPaymentPeriod = communityPayments?.find(
        (payment) => payment.year === +year && payment.month === +month
      );
      if (chosenPaymentPeriod) {
        getJobs({
          query: {
            filters: {
              idJob: chosenPaymentPeriod.jobIds,
              paymentIds: chosenPaymentPeriod.paymentIds,
              idUser,
            },
          },
        });
      }
    } else {
      setJobs([]);
    }
  };

  const handleAddBonusConfirm = async (newBonus: Partial<CommunityPayment>) => {
    try {
      if (!idUser || !newBonus) return;
      await communityPaymentsService.createNewPayment({
        ...newBonus,
        idUser: +idUser,
      });
      toastSuccess(t("bonus_created"));
      if (idUser) {
        await getUserCommunityPayments(+idUser);
      }
      closeAddBonusModal();
    } catch (err: any) {
      console.log("err :", err);
      toastError(t("create_bonus_failed"));
    }
  };

  const closeAddBonusModal = (): void => {
    clearModalContent();
  };

  function isLastMonth(month: number, year: number) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let previousMonth = currentMonth - 1;
    let previousYear = currentYear;

    if (previousMonth === 0) {
      previousMonth = 12;
      previousYear--;
    }

    return year === previousYear && month === previousMonth;
  }

  function getAvailableMonthsToGrantBonus() {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const dates = [];
    const lastMonth = formatMonthObject(endOfMonth(subMonths(currentDate, 1)));
    const thisMonth = formatMonthObject(currentDate);
    if (!year && !month) {
      dates.push(thisMonth);
      if (currentDay < 10) {
        dates.push(lastMonth);
      }
    }
    if (year && month) {
      if (isLastMonth(+month, +year)) {
        dates.push(lastMonth);
      } else if (currentMonth === +month && currentYear === +year) {
        dates.push(thisMonth);
      }
    }

    return dates;
  }

  const openCommunityBonusModal = () => {
    if (!availableMonthToGrantBonus.length) return;
    setModalType("info");
    setModalContent(
      <CommunityBonusForm
        monthsOptions={availableMonthToGrantBonus}
        onConfirm={async (newBonus) => {
          if (!newBonus) return;
          await handleAddBonusConfirm(newBonus);
        }}
        cancel={closeAddBonusModal}
        userName={`${clickedUser?.firstName} ${clickedUser?.lastName}`}
      />
    );
  };

  const handleOnCommunityPaymentDeleteApprove = async (
    idCommunityPayments: number
  ) => {
    try {
      const result = await communityPaymentsService.removePayments(
        idCommunityPayments
      );
      if (result.idPayment) {
        await getUserCommunityPayments(clickedUser?.idUser);
      }
      toastSuccess(t("delete_community_payment_success"));
    } catch (e) {
      toastSuccess(t("delete_community_payment_fail"));
    }
  };

  const onRemoveBonus = (idCommunityPayment: number) => {
    if (!idCommunityPayment) return;

    setModalType("info");
    setModalContent(
      <DeleteCommunityPaymentModal
        idCommunityPayment={idCommunityPayment}
        onApprove={handleOnCommunityPaymentDeleteApprove}
        closeModal={clearModalContent}
      />
    );
  };

  return (
    <Grid
      className={"Community Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11}>
        <Breadcrumbs crumbs={getCrumbs()} />
      </Grid>
      {month && year ? (
        <CommunityMemberMonthPaymentsTable
          month={month}
          year={year}
          jobs={jobs as JobWithPayment[]}
          isJobsLoading={isJobsLoading}
          openCommunityBonusModal={openCommunityBonusModal}
          shouldShowBonusModal={!_.isEmpty(availableMonthToGrantBonus)}
          onRemoveBonus={onRemoveBonus}
        />
      ) : clickedUser ? (
        <CommunityMemberProfile
          user={clickedUser}
          communityPayments={communityPayments}
          metaData={paymentsMetadata}
          isLoading={isPaymentsLoading}
          openCommunityBonusModal={openCommunityBonusModal}
        />
      ) : (
        <CommunityTable
          users={users}
          getUsers={getUsers}
          metaData={metaData}
          isLoading={isUsersLoading}
          defaultQuery={defaultQuery}
          hasMore={hasMore}
          totalUsers={totalUsers}
        />
      )}
    </Grid>
  );
};
