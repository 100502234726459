import config from "../../config";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";
import { UserRole } from "@sumit-platforms/types";

const Guest = () => {
  const navigate = useNavigate();
  const { user } = useAuth({ config });

  useEffect(() => {
    if (user?.roles) {
      if (user?.roles?.some((role) => role.idRole === UserRole.ADMIN)) {
        navigate("/");
      } else {
        navigate("/my-jobs");
      }
    }
  }, [user?.roles]);
  return <Outlet />;
};

export default Guest;
