import React, { useCallback, useState, useTransition } from "react";
import { Button, Modal } from "../../../index";
import Box from "@mui/material/Box";
import { Trans, useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import "./UnSplitModal.scss";

interface UnSplitModal {
  idJobs: number[];
  onApprove: (idJobs: number[]) => void;
  closeModal: () => void;
}

export const UnSplitModal = ({
  idJobs,
  onApprove,
  closeModal,
}: UnSplitModal) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleOnApprove = useCallback(async () => {
    setLoading(true);
    try {
      await onApprove(idJobs);
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <Modal closeModal={closeModal} type={"info"}>
      <Box className={"UnSplitModal"}>
        <Box textAlign={"center"}>
          <Typography variant="h5" color="text.primary">
            {t("unsplit_job")}
          </Typography>
        </Box>
        <Box py={1} textAlign={"center"}>
          <Typography variant="body2" color="text.secondary">
            <Trans
              i18nKey={t("unsplit_job_description")}
              components={{
                br: <br />,
              }}
            />
          </Typography>
        </Box>
        <Box
          component={"div"}
          pt={1}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          <Button variant={"outlined"} onClick={closeModal}>
            {t("cancel")}
          </Button>
          <Button onClick={handleOnApprove} loading={loading} color={"warning"}>
            {t("un_split")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
